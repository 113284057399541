<template>
  <section class="container690 AwardDetail">
    <iq-card body-class="profile-page p-0" class="iqCard">
      <template v-slot:body>
        <div class="profile-header-image h-100">
          <div class="cover-container">
            <el-skeleton v-if="loading" animated>
              <template slot="template">
                <el-skeleton-item variant="image" style="width: 100%; height: 230px;" />
              </template>
            </el-skeleton>
            <img v-else :src="basic.avatar" alt="profile-bg" style="height: 250px; object-fit: cover"
              class="backImg img-fluid w-100" />
          </div>
        </div>
        <div class="p-3">
          <div class="mb-2 title">{{ basic.title }}</div>
          <div class="d-flex align-items-center Share">
            <!-- <img :src="basic.user_avatar" alt="" />
            <div class="name border-right">
              {{ basic.user_nick_name }}
            </div> -->
            <ShareBtn :shareData="basic" shareType="7" />
          </div>

          <ul class="p-0 m-0 award-title">
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M6 8V7a6 6 0 1 1 12 0v1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h2zm13 2H5v10h14V10zm-8 5.732a2 2 0 1 1 2 0V18h-2v-2.268zM8 8h8V7a4 4 0 1 0-8 0v1z"
                  fill="rgba(58,58,58,1)" />
              </svg>
              <span>
                {{ basic.organization_name }}
              </span>
            </li>
            <li>
              <i style="font-size: 18px; color: #333" class="ri-t-box-line"></i>
              <span> Language: {{ basic.languages_str }} </span>
            </li>
          </ul>
          <div class="originalPrice">
            <div style="padding-left: 3px" v-if="basic.is_show_judge == 1">
              <img style="width: 14px" src="@/assets/images/icon/clipboard.png" alt="" />
              <a @click="showApplyForm()" v-show="
                  basic.user_judge_status != 1 && basic.user_judge_status != 2
                " style="text-decoration: underline" class="pl-3 mouseStyle">Apply as Judge</a>
              <a v-show="basic.user_judge_status == 1" class="pl-3 mouseStyle">User has applied as a Judge.</a>
              <a v-show="basic.user_judge_status == 2" class="pl-3 mouseStyle">User application passed</a>
            </div>
            <div v-else></div>
            <div>
              Application Fee:<span>${{ basic.price }}</span>
            </div>
          </div>
        </div>
      </template>
    </iq-card>
    <!-- 选项卡 -->
    <iq-card body-class="profile-page p-0">
      <template v-slot:body>
        <tab-nav :tabs="true" id="myTab-1" class="tabsWidthScroll">
          <tab-nav-items :active="true" id="introduction-tab" ariaControls="introduction" role="tab"
            :ariaSelected="true" @click.native="getAwardDetail(2)" title="Introduction" />
          <tab-nav-items :active="false" id="event-tab" v-show="tabList.event" ariaControls="event" role="tab"
            :ariaSelected="false" title="Event" @click.native="getAwardDetail(9)" />
          <!-- <tab-nav-items
            :active="false"
            id="judges-tab"
            ariaControls="judges"
            role="tab"
            :ariaSelected="false"
            title="Judges"
            @click.native="getAwardDetail(3)"
          /> -->

          <tab-nav-items :active="false" id="certificate-tab" v-show="tabList.certificate" ariaControls="certificate"
            role="tab" :ariaSelected="false" title="Certificate" @click.native="getAwardDetail(4)" />
          <!-- <tab-nav-items
            :active="false"
            id="campaigner-tab"
            ariaControls="campaigner"
            role="tab"
            :ariaSelected="false"
            title="Campaigner"
            @click.native="getAwardDetail(5)"
          /> -->
          <tab-nav-items :active="false" v-show="tabList.winners" id="winners-tab" ariaControls="winners" role="tab"
            :ariaSelected="false" title="Winners" @click.native="getWinners()" />
        </tab-nav>
        <tab-content id="myTabContent">
          <!-- Introduction -->
          <tab-content-item :active="true" id="introduction" aria-labelled-by="introduction-tab">
            <Introduction :introduction="introduction" />
          </tab-content-item>
          <!-- event -->
          <tab-content-item :active="false" id="event" aria-labelled-by="event-tab">
            <Event :event="event" />
          </tab-content-item>
          <!-- Judges -->
          <!-- <tab-content-item
            :active="false"
            id="judges"
            aria-labelled-by="judges-tab"
          >
            <Judges :judges="judges" :eventList="eventList" @updateJudesData="getAwardDetail(3)" />
          </tab-content-item> -->
          <tab-content-item :active="false" id="certificate" aria-labelled-by="certificate-tab">
            <Certificate :certificate="certificate" @updateCertifyData="getAwardDetail(4)" />
          </tab-content-item>
          <!-- Campaigner -->
          <!-- <tab-content-item
            :active="false"
            id="campaigner"
            aria-labelled-by="campaigner-tab"
          >
            <Campaigner :campaigner="campaigner" />
          </tab-content-item> -->
          <!-- Winners -->
          <tab-content-item :active="false" id="winners" aria-labelled-by="winners-tab">
            <Winners ref="winners" />
          </tab-content-item>
        </tab-content>
      </template>
    </iq-card>

    <el-dialog title="Apply as Judge" :visible.sync="applyJudge" custom-class="width_40">
      <div style="border-top: 1px solid #eee; padding-top: 15px">
        <el-form label-width="120px" label-position="left">
          <el-form-item label="Event">
            <el-select value-key="event_id" @change="seleEventHandle" v-model="selEventOpt" style="width: 100%">
              <el-option v-for="(v, i) of eventOptList" :key="i" :value="v" :label="v.event_name"></el-option>
            </el-select>
            <div class="d-flex flex-wrap p-2 maxTag">
              <el-tag closable @close="delSelEvent(i)" v-for="(v, i) of selEventTitle" :key="i" class="mr-2 mt-2" style="
                  height: 46px;
                  border-radius: 10px;
                  padding: 6px 25px 6px 15px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  position: relative;
                " type="info">
                {{ v }}
              </el-tag>
            </div>
          </el-form-item>
          <el-form-item label="Language" v-show="languageList.length>0">
            <el-select @change="seleLanHandle" v-model="selectLanOpt" style="width: 100%">
              <el-option v-for="(v, i) of languageList" :key="i" :value="v" :label="v"></el-option>
            </el-select>
            <div class="d-flex flex-wrap p-2 maxTag">
              <el-tag closable @close="delSelLang(i)" v-for="(v, i) of judgeForm.language" :key="i" class="mr-2 mt-2"
                style="
                  height: 46px;
                  border-radius: 10px;
                  padding: 6px 25px 6px 15px;
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  position: relative;
                " type="info">
                {{ v }}
              </el-tag>
            </div>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="agreeTerms" text-color="#3f4e14d"></el-checkbox>
            Please check &nbsp;&nbsp;<span style="color: #66b1ff; cursor: pointer"
              @click="openTermActive = true">terms</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" style="border-top: 1px solid #eee; padding-top: 15px">
        <el-button type="danger" plain @click="applyJudge = false">Cancel</el-button>
        <el-button :disabled="!agreeTerms" type="primary" @click="applyProgramJudge">Submit</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Terms" :visible.sync="openTermActive"  custom-class="width_30">
      <div class="terms" v-html="basic.term"></div>
      <!-- <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </div> -->
    </el-dialog>
  </section>
</template>
<script>
  import Introduction from "./Components/AwardDetail/Introduction";
  import Judges from "./Components/AwardDetail/Judges";
  import Event from "./Components/AwardDetail/Event";
  import Certificate from "./Components/AwardDetail/Certificate";
  import Campaigner from "./Components/AwardDetail/Campaigner";
  import Winners from "./Components/AwardDetail/Winners";
  import ShareBtn from "@/components/ComComponents/ShareBtn";
  import OrganazationSel from "@/components/ComComponents/OrganazationSel";
  import userSearchInputList from "./Components/userSearchInputList";
  export default {
    name: "Award",
    components: {
      Introduction,
      Event,
      Judges,
      Certificate,
      Campaigner,
      Winners,
      ShareBtn,
      OrganazationSel,
      userSearchInputList,
    },
    data() {
      return {
        fileList: [],
        applyJudge: false,
        agreeTerms: false,
        openTermActive: false,
        basic: {},
        introduction: {},
        judges: {},
        event: [],
        certificate: [],
        campaigner: {},
        // winner_year: {},
        winners: {},
        winnerFirstYear: "",

        organization_id: 0,

        // 申请评委
        languageList: [],
        eventOptList: [],
        selEventTitle: [],
        selEventOpt: {},
        selectLanOpt: "",
        judgeForm: {
          event: [],
          language: [],
        },

        selectMemberRow: {},
        pushMemberList: [],
        alartStatus: "",

        eventList: [],

        elect_info: {},
        tabList: {},
        loading: true,
      };
    },
    created() {
      this.getAwardDetail(1);
      this.getAwardDetail(2);
      this.getTab();
    },

    watch: {},
    methods: {
      delSelEvent(i) {
        this.judgeForm.event.splice(i, 1);
        this.selEventTitle.splice(i, 1);
      },
      delSelLang(i) {
        this.judgeForm.language.splice(i, 1);
      },
      getTab() {
        this.$http.getProgramDetail({ type: "tab", program_id: this.$route.query.id }).then((res) => {
          if (res.status == 200) {
            this.tabList = res.data;
          }
        });
      },
      getWinners() {
        // this.getAwardDetail(6);
        this.$refs.winners.getWinersInfo();
      },
      seleEventHandle() {
        let res = this.judgeForm.event.indexOf(this.selEventOpt.event_id);
        if (res == -1) {
          this.judgeForm.event.push(this.selEventOpt.event_id);
          this.selEventTitle.push(this.selEventOpt.event_name);
          this.selEventOpt = {}
        }
      },
      seleLanHandle() {
        let res = this.judgeForm.language.indexOf(this.selectLanOpt);
        if (res == -1) {
          this.judgeForm.language.push(this.selectLanOpt);
          this.selectLanOpt = ""
        }
      },
      // 打开申请评委弹框
      showApplyForm() {
        if (this.basic.judge_status == 1) {
          this.$message.warning("User has applied");
        } else if (this.basic.judge_status == 2) {
          this.$message.warning(
            "The application has passed. Please do not submit it again"
          );
        } else {
          this.judgeForm.event = [];
          this.judgeForm.language = [];
          this.selEventTitle = [];
          this.selEventOpt = {};
          this.selectLanOpt = "";
          this.applyJudge = true;
        }
      },
      //申请评委
      applyProgramJudge() {
        this.$http
          .applyProgramJudge({
            program_id: this.$route.query.id,
            event_id: this.judgeForm.event,
            language: this.judgeForm.language,
          })
          .then((res) => {
            if (res.status == 200) {
              this.getAwardDetail(1);
              this.applyJudge = false;
            }
          });
      },
      // 奖项详情
      async getAwardDetail(type) {
        this.loading = true
        if (type == 3) {
          this.$http
            .relationEventOptions({ program_id: this.$route.query.id })
            .then((res) => {
              if (res.status == 200) {
                this.eventList = res.data;
              }
            });
        }
        var detail = {
          1: "basic", //res.data
          2: "introduction", //res.data
          3: "judges", //res.data
          4: "certificate", //res.data.data
          5: "campaigner", //res.data.data
          // 6: "winner_year", //res.data
          // 7: "winners",
          9: "event",
        };
        const data = {
          program_id: this.$route.query.id,
          type: detail[type],
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        let res = await this.$http.getProgramDetail(data);
        if (res.status == 200) {
          if (detail[type] == "campaigner") {
            this[detail[type]] = res.data.data;
            return;
          }
          if (detail[type] == "basic") {
            this.winnerFirstYear = res.data.year;
            this[detail[type]] = res.data;
            this.eventOptList = res.data.event_options || []
            // let nowData = new Date().getTime()
            // this.eventOptList = this.eventOptList.filter(v => v.user_start < nowData && nowData < v.user_end)
            this.languageList = res.data.language_options;
            // this.btnStatus = res.data.status;//2：拒绝
            return;
          }
          if (type == 6) {
            this.winner_year = res.data.year;
            return;
          }
          if (type == 7) {
            this.winners = res.data;
            return;
          }
          this[detail[type]] = res.data;
        }
        setTimeout(() => {
          this.loading = false
        }, 0);
      },

      // 组织
      getOrganizationParams(data) {
        this.projectParams.organization_id = "";
        this.childData = JSON.parse(JSON.stringify(data));
      },
    },
  };
</script>
<style lang="scss" scoped>
  @media screen and (min-width: 992px) {
    ::v-deep #Enroll .modal-dialog {
      max-width: 800px !important;
    }
  }
  @media (min-width: 600px){
    ::v-deep .width_30{
      width: 30%;
    }
    ::v-deep .width_40{
      width: 40%;
    }
  }
  @media (max-width: 600px){
    .AwardDetail{
      padding: 15px;
    }
    ::v-deep .el-dialog{
      width: 95%;
    }
  }
  .maxTag{
    ::v-deep .el-tag .el-icon-close{
      position: absolute;
      top: 14px;
      right: 6px;
    }
  }
  
  .terms {
    ::v-deep img {
      width: 100%;
    }
    word-break: normal;
  }

  .backImg {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }

  .questDel {
    word-break: keep-all;
  }

  .outlineBlue {
    background: #50b5ff !important;
    color: #fff;
  }

  .douInt {
    height: 50px;
    width: 100%;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;

    p:first-child {
      width: 50px;
      height: 50px;
      padding: 14px;
      border: 1px solid #e6e6e6;
      border-right: none;
      background: #fff;
      text-align: center;
      cursor: pointer;
      border-end-start-radius: 5px;
      border-top-left-radius: 5px;
    }

    :last-child {
      width: 650px;
      height: 50px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 14px 0;
      padding-left: 10px;
      border: 1px solid #e6e6e6;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .my_alert {
    ::v-deep .el-alert--error.is-light {
      background-color: #f5fbff;
      color: #50b5ff;
    }
  }

  // .el-select-dropdown__item {
  //   height: 50px;
  // }
  .el-tag.el-tag--info .el-tag__close:hover {
    background: #ccc !important;
  }

  .mouseStyle {
    cursor: pointer,
  }

  .AwardDetail {
    min-height: 100vh;
    padding-top: 90px;
    position: relative;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }

    // 分享/avatar
    .Share {
      margin-bottom: 19px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .name {
        color: #333333;
        padding-right: 19px;
        margin-right: 19px;
        margin-left: 11px;
      }
    }

    .award-title {
      >li {
        padding: 7.5px 0;
        line-height: 1;

        >span {
          color: #333;
          padding-left: 10px;
        }
      }
    }

    // price
    .originalPrice {
      color: #999999;
      margin-bottom: 15px;
      margin-top: 10px;
      line-height: 24px;
      display: flex;
      justify-content: space-between;

      >div>span {
        font-size: 20px;
        font-weight: 600;
        color: #ef5959;
      }
    }

    .moduleTitle {
      display: flex;
      justify-content: space-between;
      color: #3f414d;
      font-weight: 500;

      >p.point {
        width: 120px;
        text-align: right;
      }
    }

    // upload
    .upload,
    .answers,
    .link {
      >h5 {
        font-size: 14px;

        >span {
          color: #999;
        }
      }

      // image
      >.question-ist {
        display: flex;

        >.videoBox {
          width: 90px;
          height: 90px;
          margin-right: 10px;
          position: relative;

          >img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          >i {
            font-size: 16px;
            position: absolute;
            top: -16px;
            right: -8px;
          }
        }
      }
    }

    // video
    .dialog_video {
      >p {
        color: #999;
        font-size: 12px;
      }
    }

    .self-btn {
      width: 118px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;

      &.btn_save {
        background: #3f414d;
        color: #ffffff;
      }
    }

    .btn_submit {
      background: #50b5ff;
      color: #ffffff;
    }

    .btn_cancel {
      background: #fff5f4;
      color: #ffb9ae;
      border: none;
    }
  }

  .Paid {
    // width: 150px;
    background-color: #cccccc;
    color: #fff;
    border-radius: 8px;
    margin-left: 0;
  }

  .memberlist {
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    >span {
      display: block;
      text-align: left;
    }
  }

  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>