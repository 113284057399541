<template>
  <section class="winner_container p-2 pb-5" v-if="winners.is_current||winners.is_past">
    <div class="current_winner d-flex align-items-center justify-content-between pr-5" v-if="winners.is_current">
      <div class="d-flex flex-column pl-5">
        <span style="margin-bottom: 35px;color: #333;font-size: 16px;">This year's winners</span>
        <el-button plain style="color: #50B5FF;border-color:#50B5FF;background-color: transparent"
          @click="$router.push(`/winner-detail?id=${$route.query.id}`)">Winners</el-button>
      </div>
      <img src="@/assets/images/event/1.png" alt="" width="99px">
    </div>
    <div class="past_winner" v-if="winners.is_past">
      <div class="past_item d-flex align-items-center justify-content-between px-5 mt-3"
        v-for="(v, i) in winners.past_year" :key="i">
        <div style="color: #333;font-size: 16px;">{{v}} Winners</div>
        <i class="el-icon-link" style="font-size: 22px;color:#50B5FF;cursor: pointer;"
          @click="$router.push(`/winner-detail?id=${$route.query.id}&year=${v}`)"></i>
      </div>
    </div>
  </section>
  <section class="d-flex justify-content-center align-items-center" style="background:#fff;padding: 50px;" v-else>
    <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        winners: {},
        winnersList: [],
        year: "",
        lastWinner: [],
        page: 1,
        total: 0,
        lastPage: 1,
        lastTotal: 0,
      };
    },
    methods: {
      getWinersInfo() {
        this.$http.getProgramDetail({
          program_id: this.$route.query.id,
          type: "winners",
        }).then((res) => {
          this.winners = res.data;
        });
      }
    },
  };
</script>
<style lang="scss" scoped>
  .current_winner {
    width: 100%;
    height: 140px;
    background: #f5fbff;
    border-radius: 5px;
  }

  .past_item {
    height: 80px;
    background: #f8f8f8;
    border-radius: 5px;
  }
</style>