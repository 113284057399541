<template>
  <section class="Judges">
    <div v-if="judges.judge_apply&&judges.status==3">
      <el-alert :title="`Your application was declined. The Reason is:${judges.judge_apply}`" type="error"
        :closable="false" class="mb-3">
      </el-alert>
    </div>
    <div v-if="judges.status==1" class="my_alert">
      <el-alert :title="`Its waiting for approval`" type="error" :closable="false" class="mb-3" >
      </el-alert>
    </div>
    <div class="row">
      <div style="height: 226px; cursor: pointer" class="col-sm-6 col-md-4 mb-3" v-for="v in judges.judge" :key="v.id"
        @click="
          $router.push({
            path: '/profile',
            query: {
              user_id: v.user_id,
            },
          })
        ">
        <div class="h-100 d-flex flex-column align-items-center itemContent">
          <div class="mb-3 imgBoxJudges">
            <img :src="v.avatar" alt="" />
          </div>
          <div class="mb-1 name text-overflow-single" :title="v.nick_name">{{ v.nick_name }}</div>
          <div class="mb-1 position" :title="v.position">{{ v.position | filterNone }}</div>
          <div class="country" :title="v.country" >{{ v.country | filterNone }}</div>
        </div>
      </div>
    </div>
    <div v-show="!judges.is_judge&&judges.is_apply_judge" class="applyJudes">
      <span @click="dialogVisible = true">Apply for judges</span>
    </div>
    <el-dialog title="Application form" :visible.sync="dialogVisible" width="30%">
      <!-- <div class="mb-3" v-if="eventList.length>0">
          <h5>Event</h5>
          <el-select
            v-model="selEventId"
            popper-class="optionsEvent"
            clearable
            class="w-100"
          >
            <el-option
              v-for="(item, index) of eventList"
              :key="index"
              :label="item.event_title"
              :value="item.event_id"
            ></el-option>
          </el-select>
      </div>
      <div class="dialogInput">
        <p>Reason for application</p>
        <el-input resize="none" type="textarea" :rows="10" v-model="applyJudes"></el-input>
      </div> -->
      <hr />
      <div class="my-5 introduct" v-html="judges.program.application_instructions"></div>
      <hr />
      <div class="dialogButton">
        <el-button class="cancel" @click="dialogVisible = false">Cancel</el-button>
        <el-button class="confirm" type="primary" @click="confirmAnwser">Confirm</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
  export default {
    name: "",
    props: ['judges','eventList'],
    data() {
      return {
        dialogVisible: false,
        applyJudes: "",
        selEventId:"",

      }
    },
    filters: {
      filterNone(val) {
        return val ? val : '-'
      }
    },

    methods: {
      confirmAnwser() {
        this.$http.applyProgramJudge({
          program_id: this.$route.query.id,
          apply_reason: this.applyJudes,
          event_id:this.selEventId
        }).then(res => {
          // console.log(res);
          if (res.status == 200) {
            this.dialogVisible = false;

            this.$emit('updateJudesData', true)
            this.applyJudes = "";
          }
        })
      }
    },

  };
</script>
<style>
.optionsEvent {
  width: 60vh !important;
}
</style>
<style src='' lang='scss' scoped>
  .Judges {
    min-height: 500px;
    padding: 0 15px 20px 15px;

    .itemContent {
      background: #fbfbfb;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      padding: 20px 0 28px;

      .imgBoxJudges {
        width: 80px;
        height: 80px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .name {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .position {
        font-weight: 400;
        width: 100%;
        color: #999999;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .country {
        color: #666666;
        line-height: 24px;
        width: 100%;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .introduct {
    ::v-deep img {
      width:100%;
    }
  } 
  
  .my_alert {
  ::v-deep .el-alert--error.is-light {
    background-color: #f5fbff;
    color: #50b5ff;
  }
  }
  ::v-deep .el-dialog__body {
    padding: 10px 30px;
  }
  ::v-deep .el-dialog__header {
   padding: 10px 10px;
  }
  .applyJudes {
    display: flex;
    justify-content: center;
    align-items: center;

    >span {
      display: block;
      width: 200px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      border-radius: 5px;
      color: #50b5ff;
      background-color: #f5fbff;
      cursor: pointer;
    }
  }

  .dialogInput {
    margin-bottom: 50px;

    >p {
      color: #3f414d;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .dialogButton {
    display: flex;
    justify-content: flex-end;

    .confirm {
      border-radius: 8px;
    }

    .cancel {
      border-radius: 8px;
      background-color: #fff5f4;
      color: #ffaa9d;
      border: none;
    }
  }
</style>