<template>
  <div class="eventCon">
    <div class="title">
      <h5>Event List</h5>
      <a class="mouseStyle" @click="(historyApply = true), getUserProgramApply()">View Application and Draft History</a>
    </div>
    <div class="certificate" v-for="(v, i) of event" :key="i">
      <div class="img-box">
        <el-image :src="v.event_image" fit="cover" style="width: 100%; height: 100%"></el-image>
      </div>
      <div class="between_">
        <div class="cert-info">
          <h3>{{ v.event_title }}</h3>
          <!-- <span>Application Period: {{ v.user_start_desc }} —{{ v.user_end_desc }}</span> -->
          <span>{{ v.user_start_desc }} —{{ v.user_end_desc }} {{v.time_zone}}</span>
        </div>

        <!-- <div class="test-btn" v-show="v.user_start < itemStamp && itemStamp < v.user_end">
          <b-button style="width: 90px" @click="applyQuest(v.event_id)" class="mt-4" variant="primary">
            Apply
          </b-button>
        </div> -->
        <div class="test-btn" v-show="v.is_bool">
          <b-button style="width: 90px" @click="applyQuest(v.event_id)" class="mt-4" variant="primary">
            Apply
          </b-button>
        </div>
      </div>
    </div>

    <el-dialog title="Application form" custom-class="width_60" :visible.sync="dialogVisible" :close-on-click-modal="false"
      id="application_form">
      <el-button icon="el-icon-bell" round class="notes_type" v-if="!is_select" @click="popupNote=true">Notes
      </el-button>
      <el-form ref="form" label-width="130px" label-position="top">
        <div class="mb-3" v-if="is_select">
          <el-table ref="singleTable" :data="questionList" highlight-current-row @current-change="handleCurrentChange"
            style="width: 100%" tooltip-effect="light">
            <el-table-column width="50">
              <template slot-scope="scope">
                <div class="single_box">
                  <i class="el-icon-check" v-show="scope.row.is_checked"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="questionnaire" label="Questionnaire Title" show-overflow-tooltip min-width="200">
            </el-table-column>
            <el-table-column property="language" label="Question Language" width="200" align="center"
              header-align="center">
            </el-table-column>
          </el-table>
        </div>

        <!-- 问卷详情 -->
        <section v-else class="question_content">
          <h4 class="mb-3" v-if="question.questionnaire">{{question.questionnaire}}({{question.language}})</h4>
          <div v-for="(model, index) in question.all_topic" :key="model.id">
            <div class="moduleTitle">
              <p style="max-width: 86%">
                {{ `Section ${index + 1}` }}:&nbsp;&nbsp;{{ model.classification }}
              </p>
              <p class="point ml-3">{{ model.score }}Points</p>
            </div>
            <ul class="p-0">
              <li class="mb-4" v-for="(quest, i) in model.topic" :key="quest.id">
                <h5 class="mt-1 pl-3 question-title" style="">
                  <p class="questDel">
                    <b class="mr-3" style="color: #3f414d; font-weight: 500">{{`Question ${index + 1}.${i + 1}`}}</b>{{ quest.topic
                    }}
                    <span class="ml-2" v-if="quest.type == 3&&quest.size" style="color: #999;">(limit:
                      {{quest.size}})</span>
                  </p>
                </h5>
                <div class="pl-3 answers">
                  <h5 class="mb-2">Answer
                    <span style="color: #f00" v-if="quest.is_required">*</span>
                    <span class="ml-2">({{{1:'Multiple choice',2:'Select Member',3:'Fill'}[quest.type]}})</span>
                  </h5>
                  <!-- 1不定项选择 -->
                  <div v-if="quest.type == 1" class="d-flex flex-wrap">
                    <div class="douInt cursor-pointer" v-for="(v, idx) of quest.option" :key="idx"
                      @click="selectAnswer(quest, idx)">
                      <p :class="{ outlineBlue: quest.user_answer.includes(String(idx)) }">{{ numList[idx] }}</p>
                      <p :title="v">{{ v }}</p>
                    </div>
                  </div>

                  <!-- 2.选择会员 -->
                  <div v-if="quest.type == 2" class="">
                    <userSearchInputList @userItem="(user) => {getUserItem(user, quest)}" />
                    <div class="member-tag-list mb-3 mt-3">
                      <el-tag v-for="(tag, index) in quest.user_answer" :key="index" type="info" class="mr-2 mb-2"
                        @close="quest.user_answer.splice(index, 1)" closable>
                        {{ tag.nick_name }}
                      </el-tag>
                    </div>
                  </div>

                  <!-- 3.填空 -->
                  <el-input v-if="quest.type == 3" v-model="quest.user_answer" type="textarea"
                    style="word-break: break-word" :rows="3" resize="none" @blur="val=>limitInput(val,quest)">
                  </el-input>
                  <div style="height: 20px;">
                    <p v-show="quest.warning" style="color:#F56C6C;">Cannot exceed {{quest.size}} words</p>
                  </div>

                  <div v-show="quest.picture" class="upload mb-3">
                    <h5 class="mt-2 d-flex">
                      Upload Pictures
                      <div class="ml-3" style="color: #999;word-break: break-word;">
                        <span>*You can upload up to 9 pictures</span>
                        <p class="ml-2">Maximum upload file size: 1MB</p>
                      </div>
                    </h5>
                    <div class="question-ist">
                      <div v-for="(img, index) in quest.image" :key="index" class="videoBox">
                        <img width="100%" :src="img" alt />
                        <i @click="quest.image.splice(index, 1)" class="ri-close-circle-line cursor-pointer"></i>
                      </div>
                      <div class="push_img" v-loading="quest.loading">
                        <el-upload action="no" :http-request="(params) => {uploadFileIMG(params, quest)}"
                          accept="image/*" :show-file-list="false">
                          <i class="el-icon-plus_S">
                            <img id="mi_img" class="mi_img" src="@/assets/images/settings/camera.png" alt />
                          </i>
                        </el-upload>
                      </div>
                    </div>
                  </div>
                  <div v-show="quest.url" class="mt-3 mb-3 link">
                    <h5 class="mb-2" style="word-break: break-word;">
                      External links for supporting documents(If Applicable)
                    </h5>
                    <el-input class="mb-1" v-model="quest.link">
                      <template #prepend>Link:</template>
                    </el-input>
                  </div>
                </div>
              </li>
            </ul>
            <hr />
          </div>
        </section>
        <div class="d-flex justify-content-center mt-5">
          <el-button class="btn_cancel" @click="funCancel">Cancel</el-button>
          <el-button @click="is_select=true" class="btn_submit" v-if="!is_select&&!apply_id">Previous
          </el-button>
          <el-button @click="getProgramQuestionnaireInfo" class="btn_submit" v-if="is_select">Next</el-button>
          <el-button style="background: #4266af; color: #fff" v-if="!is_select" @click="submitProgramQuestionnaire"
            :disabled="disabled">Confirm
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <!-- 问卷答题记录列表 -->
    <el-dialog title="View Application History" custom-class="width_50" :visible.sync="historyApply">
      <el-table :data="historyList" style="width: 100%" tooltip-effect="light">
        <el-table-column prop="event_title" label="Event" show-overflow-tooltip></el-table-column>
        <el-table-column min-width="130px" prop="questionnaire_title" label="Questionnaire" show-overflow-tooltip>
        </el-table-column>
        <el-table-column min-width="150px" prop="apply_time" label="Application Date" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="date" label="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.payment_status == 0">Unpaid</span>
            <span v-else-if="scope.row.payment_status == 1 && scope.row.audit_status == 1">Passed</span>
            <span v-else-if="scope.row.payment_status == 1 && scope.row.audit_status == 2">Fail</span>
            <span v-else-if="scope.row.payment_status == 1 && scope.row.audit_status == 0">Pending</span>
          </template>
        </el-table-column>
        <el-table-column label="Action" width="90">
          <template slot-scope="scope">
            <div class="d-flex justify-content-center"
              v-if="scope.row.audit_status != 0 && scope.row.payment_status != 0">
              <span>—</span>
            </div>
            <div v-else class="colorBlue d-flex justify-content-center">
              <span v-if="scope.row.payment_status == 0" class="mouseStyle pr-2"
                @click="programPayPackage(scope.row.id)">Pay
              </span>
              <span v-show="scope.row.audit_status == 0" class="mouseStyle" @click="editQuestInfo(scope.row.id)">
                Edit</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div slot="footer" class="bottomof" style="text-align: right; margin-top: 30px; margin-bottom: 10px">
        <el-pagination :current-page="page" background layout="prev, pager, next" :total="total" :page-size="size"
          @current-change="handleChangePage" hide-on-single-page></el-pagination>
      </div> -->
    </el-dialog>

    <!-- notes展示弹框 -->
    <el-dialog title="Notes" :visible.sync="popupNote" custom-class="width_500">
      <div class="notes_content">{{notes}}</div>
    </el-dialog>
  </div>
</template>
<script>
  import userSearchInputList from "../../Components/userSearchInputList";
  export default {
    data() {
      return {
        total: 20,
        size: 10,
        page: 1,
        dialogVisible: false,
        historyApply: false,
        questionList: [], //问卷列表
        question: [],
        historyList: [],
        questionnaire_id: '',
        is_select: true,
        apply_id: '',
        numList: ["A", "B", "C", "D", "E", "F", "G",
          "H", "I", "J", "K", "L", "M", "N", "O", "P",
          "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
        ],
        popupNote: false,
        notes: '',
        disabled: false
      };
    },
    components: { userSearchInputList },
    computed: {
      itemStamp() {
        return new Date().getTime();
      },
    },
    props: ["event"],
    methods: {
      // 打开选择问卷弹框
      applyQuest(id) {
        this.apply_id = ''
        this.event_id = id
        this.is_select = true
        this.questionnaire_id = ''
        this.getProgramQuestionnaire()
        this.dialogVisible = true;
      },

      async limitInput(val, quest) {
        if (!quest.size) return
        let res = await this.$http.getContentNumber({ keyword: quest.user_answer })
        if (res.status == 200) {
          if (quest.size < res.data) {
            quest.warning = true
          } else {
            quest.warning = false
          }
        }
        this.isDisabled()
        // console.log(quest);

        // let exp = /\\|\/|\?|\)|\(|\*|\"|\'|\,|\<|\>|\{|\}|\[|\]|\:|\.|\^|\$|\!|\~|\`|\||\@|\#|\%|\_|\;/g
        // let expChinese = /\。|\，|\【|\】|\）|\（|\？|\·|\、|\-|\=|\+|\《|\》|\……|\“|\”|\‘|\’|\！|\￥|\——|\：|\；|\·/g
        // let regEx = /\s+/g
        // let num = val.replace(/[^\d]/g, '')
        // let str = val.replace(/[^\u4e00-\u9fa5]/gi, '')
        // let newVal = val.replace(exp, ' ').replace(expChinese, ' ').replace(/[0-9]/ig, ' ').replace(/[\u4e00-\u9fa5]/gi, '').replace(regEx, ' ')

        // console.log("num", num);
        // console.log("str", str);
        // console.log("newVal", newVal);

        // let engArray = (newVal.trim().length && newVal.split(' ')) || []
        // let newArray = engArray.filter(v => { return v != '' })

        // console.log("engArray", newArray);

        // let totalLength = num.length + str.length + newArray.length

        // console.log('totalLength', totalLength);
        // console.log('totalLength', num.length + ' ' + str.length + ' ' + newArray.length);
        // console.log('flag', this.flag);

        // if (totalLength == quest.size) {
        //   this.words = val
        // } else if (totalLength > quest.size) {
        //   if (!this.flag) {
        //     this.$message.warning(`No more than ${quest.size} words`)
        //   }
        //   this.flag = true
        //   if (totalLength > quest.size + 1) {
        //     this.flag = false
        //     quest.user_answer = this.words
        //   }
        // } else {
        //   this.words = val
        //   this.flag = false
        // }
        // if (this.flag) quest.user_answer = this.words

        // console.log('this.words', this.words);
        // console.log('quest.user_answer', quest.user_answer);
      },

      // 处理quest.warning
      isDisabled() {
        this.disabled = false
        this.question.all_topic.forEach(v => {
          v.topic.forEach(ele => {
            if (ele.warning) this.disabled = true
          })
        })
      },

      // 获取问卷列表
      async getProgramQuestionnaire() {
        let res = await this.$http.getProgramQuestionnaire({ program_id: this.$route.query.id })
        if (res.status == 200) {
          this.questionList = res.data
          this.questionList.forEach(v => this.$set(v, 'is_checked', false))
        }
      },

      // 选择问卷
      handleCurrentChange(val) {
        this.questionList.forEach(v => v.is_checked = false)
        if (val) {
          val.is_checked = true
          this.questionnaire_id = val.id
        }
      },

      // 获取问卷详情
      async getProgramQuestionnaireInfo() {
        if (this.questionnaire_id) {
          let res = await this.$http.getProgramQuestionnaireInfo({
            questionnaire_id: this.questionnaire_id,
            program_id: this.$route.query.id
          })
          if (res.status = 200) {
            this.question = res.data
            this.notes = res.data.notes
            this.question.all_topic.forEach(v => {
              v.topic.forEach(ele => {
                this.$set(ele, 'image', [])
                this.$set(ele, 'link', '')
                this.$set(ele, 'loading', false)
                this.$set(ele, 'warning', false)
                if (ele.type == 3) {
                  this.$set(ele, 'user_answer', '')
                } else {
                  this.$set(ele, 'user_answer', [])
                }
              })
            })
            this.is_select = false
            this.disabled = false
          }
        } else {
          this.$message.warning('Please select a questionnaire')
        }
      },

      // 单选/多选答案
      selectAnswer(v, index) {
        let i = String(index)
        if (v.user_answer.includes(i)) {
          let arr = v.user_answer
          let index = arr.map(item => item).indexOf(i)
          v.user_answer.splice(index, 1)
        } else {
          v.user_answer.push(i)
        }
      },

      // 上传图片
      async uploadFileIMG(param, question) {
        if (!question.image.length) question.image = []
        question.loading = true
        let formData = new FormData();
        formData.append("file", param.file);
        let res = await this.$http.uploadImage(formData)
        if (res.status == 200) {
          question.image.push(res.data[0]);
        }
        question.loading = false
      },


      //會員列表
      getUserItem(row, quest) {
        let { user_id, nick_name } = row;
        if (quest.user_answer.length) {
          let result = quest.user_answer.filter(item => item.user_id == user_id);
          if (result.length) {
            this.$message("Data already exists!");
          } else {
            quest.user_answer.push({ user_id, nick_name });
          }
        } else {
          quest.user_answer.push({ user_id, nick_name });
        }
      },

      // 提交问卷
      submitProgramQuestionnaire() {
        setTimeout(() => {
          if (this.disabled) return
          this.handlerBlur()
        }, 500);
      },

      async handlerBlur() {
        let newData = JSON.parse(JSON.stringify(this.question.all_topic))
        newData.forEach(v => {
          v.topic.forEach(ele => {
            if (ele.type == 2) {
              ele.user_answer = ele.user_answer.map(item => {
                return item.user_id
              })
            }
          })
        })
        let data = {
          program_id: this.$route.query.id,
          event_id: this.event_id,
          apply_id: 0 || this.apply_id,
          questionnaire_id: this.questionnaire_id,
          all_topic: JSON.stringify(newData)
        }
        let res = await this.$http.submitProgramQuestionnaire(data)
        if (res.status == 200) {
          if (this.apply_id) {
            this.dialogVisible = false
            this.historyApply = true
          } else {
            this.programPayPackage(res.data.apply_id);
            this.dialogVisible = false
          }
        }
        this.apply_id = ''
      },

      // 支付
      async programPayPackage(id) {
        let data = { apply_id: id, time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone }
        let res = await this.$http.programPayPackage(data)
        if (res.status == 200) {
          if (res.data.is_jump) {
            this.$router.push({ path: "/PayMoney", query: { model_type: 6, id: res.data.order_id, model_id: this.$route.query.id } });
          } else {
            this.$router.push({ path: `/PaySuccess`, query: { model_type: 6, model_id: this.$route.query.id } });
          }
        }
      },

      //  问卷申请记录列表
      async getUserProgramApply() {
        let res = await this.$http.getUserProgramApply({ program_id: this.$route.query.id })
        if (res.status == 200) {
          this.historyList = res.data;
          this.total = res.data.total;
        }
      },

      // 编辑问卷
      async editQuestInfo(id) {
        this.is_select = false
        this.historyApply = false;
        this.dialogVisible = true;
        this.apply_id = id;
        let res = await this.$http.getProgramApplyInfo({ apply_id: id })
        if (res.status == 200) {
          this.question = res.data
          this.notes = res.data.notes
          this.question.all_topic.forEach(v => {
            v.topic.forEach(ele => {
              this.$set(ele, 'loading', false)
              if (ele.type == 2) {
                let user_answer = ele.user_answer
                ele.user_answer = user_answer.map((item, i) => {
                  return { user_id: item, nick_name: ele.users[i] }
                })
              }
            })
          })
          this.event_id = this.question.event_id
          this.questionnaire_id = this.question.questionnaire_id
        }
      },

      // 取消
      funCancel() {
        this.dialogVisible = false
        if (this.apply_id) {
          this.historyApply = true
          this.apply_id = ''
        }
      },

      handleChangePage(num) {
        this.page = num;
        this.getUserProgramApply();
      }

    },
  };
</script>
<style lang="less" scoped>
  /deep/ #application_form .el-dialog {
    margin-top: 2vh !important;
  }
@media (min-width: 600px){
::v-deep .width_60{
    width: 60%;
  }
  ::v-deep .width_50{
    width: 50%;
  }
  ::v-deep .width_500{
    width: 500px;
  }
  .certificate {
    padding: 20px;
    .img-box {
      width: 148px;
      height: 90px;
    }
    .between_ {
      width: calc(100% - 148px);
      .cert-info {
        h3 {
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 600px){
::v-deep .el-dialog{
    width: 95%;
  }
  .certificate {
    padding: 10px;
    .img-box {
      width: 100px;
      height: 60px;
    }
    .between_ {
      width: calc(100% - 100px);
      .cert-info {

        h3 {
          font-size: 12px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
        }
        >span{
          font-size: 10px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          display: -webkit-box;
        }
      }
    }
  }
}
  .eventCon {
    padding: 15px;

    .title {
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: underline;
      }
    }

    .certificate {
      display: flex;
      border-bottom: 1px solid #f1f1f1;

      .img-box {
        border: 1px solid #ddd;
        border-radius: 6px;
        overflow: hidden;

        >img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .between_ {
        display: flex;
        justify-content: space-between;

        .cert-info {
          padding-left: 10px;

          h3 {
            color: #333;
            font-weight: 500;
          }

          >span {
            font-size: 12px;
            color: #999999;
          }
        }

        >i {
          font-size: 20px;
          color: #57b8ff;
        }
      }

      .test-btn {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >i {
          font-size: 20px;
          color: #57b8ff;
        }
      }
    }

    .moduleTitle {
      display: flex;
      justify-content: space-between;
      color: #3f414d;
      font-weight: 500;

      >p.point {
        width: 120px;
        text-align: right;
      }
    }

    .question-title {
      font-size: 14px;
      font-weight: 500;
      color: #3f414d;

      >span {
        display: block;
      }

      .questDel {
        word-break: break-word;
      }
    }

    .upload,
    .answers,
    .link {
      >h5 {
        font-size: 14px;

        >span {
          color: #999;
        }
      }

      // image
      >.question-ist {
        display: flex;

        >.videoBox {
          width: 90px;
          height: 90px;
          margin-right: 10px;
          position: relative;

          >img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          >i {
            font-size: 16px;
            position: absolute;
            top: -16px;
            right: -8px;
          }
        }
      }
    }

    .self-btn {
      width: 118px;
      height: 40px;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;

      &.btn_save {
        background: #3f414d;
        color: #ffffff;
      }
    }

    .btn_submit {
      background: #50b5ff;
      color: #ffffff;
    }

    .btn_cancel {
      background: #fff5f4;
      color: #ffb9ae;
      border: none;
    }

    .my_alert {
      ::v-deep .el-alert--error.is-light {
        background-color: #f5fbff;
        color: #50b5ff;
      }
    }

    .el-tag.el-tag--info .el-tag__close:hover {
      background: #ccc !important;
    }

    ::v-deep .el-pagination {

      .btn-prev,
      .btn-next,
      .number {
        border-radius: 50% !important;
      }

      .el-pager {
        border-radius: 14px;
        background-color: #f0f0f0;

        .number {
          margin: 0 !important;
        }
      }
    }

    .douInt {
      height: 50px;
      width: 100%;
      margin-top: 10px;
      margin-right: 10px;
      display: flex;

      p:first-child {
        width: 50px;
        height: 50px;
        padding: 14px;
        border: 1px solid #e6e6e6;
        border-right: none;
        background: #fff;
        text-align: center;
        cursor: pointer;
        border-end-start-radius: 5px;
        border-top-left-radius: 5px;
      }

      :last-child {
        width: 850px;
        height: 50px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 14px 0;
        padding-left: 10px;
        border: 1px solid #e6e6e6;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    .outlineBlue {
      background: #50b5ff !important;
      color: #fff;
    }

    .colorBlue {
      color: #50b5ff;
    }

    .mouseStyle {
      cursor: pointer;
    }

    .single_box {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid #777d74;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 12px !important;
      }
    }

    .notes_type {
      background-color: #fff;
      border-color: #FFBA68;
      color: #FFBA68;
      position: absolute;
      right: 60px;
      top: 12px;
    }

    .question_content {
      padding-right: 5px;
      /* max-height: 620px; */
      max-height: 72vh;
      overflow-y: auto;
    }
  }
</style>