<template>
  <section class="Campaigner" style="height: 300px; overflow: scroll">
    <div class="row">
      <div
        @click="
          $router.push({ path: '/profile', query: { user_id: v.user_id } })
        "
        class="col-md-6 mb-3"
        v-for="(v, i) in campaigner"
        :key="i"
      >
        <div class="d-flex itemContent">
          <div class="avatar">
            <img :src="v.avatar" alt="" />
          </div>
          <div class="d-flex flex-column justify-content-around">
            <div class="name">{{ v.nick_name }}</div>
            <div class="position">
              {{ v.organization_name | filterUnknown }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: '',
  props: ['campaigner'],

  components: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created () { }
}
</script>
<style src='' lang='scss' scoped>
.Campaigner {
  padding: 0 15px 20px 15px;
  .itemContent {
    margin-bottom: 20px;
    .avatar {
      width: 60px;
      height: 60px;
      background: #ffffff;
      border: 2px solid #e7e7e7;
      border-radius: 50%;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 17px;
      color: #333333;
      line-height: 26px;
    }
    .position {
      color: #999999;
      line-height: 26px;
    }
  }
}
</style>
