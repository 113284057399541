var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"introduce"},[_c('div',{staticClass:"intro",domProps:{"innerHTML":_vm._s(_vm.introduction&&_vm.introduction.introduction)}}),(_vm.sponsorLarge&&_vm.sponsorLarge.length || _vm.sponsorSmall&&_vm.sponsorSmall.length)?_c('section',{staticClass:"sponsors"},[_c('h3',[_vm._v("Sponsor")]),_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.sponsorLarge),function(v,i){return _c('el-col',{key:i,attrs:{"xs":12,"sm":8}},[_c('div',{staticClass:"img-box",on:{"click":function($event){return _vm.$router.push({
              path: '/profile',
              query: {
                user_id: v.user_id,
              },
            })}}},[_c('el-image',{staticStyle:{"width":"90px","height":"90px","cursor":"pointer"},attrs:{"src":v.avatar,"fit":"cover"}}),(v.role == 3)?_c('a',{class:{ personalClass: v.role == 3 ? true : false },attrs:{"target":"__blank","href":v.link}},[_vm._v(_vm._s(v.name))]):_c('span',[_vm._v(_vm._s(v.name))])],1)])}),1),_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.sponsorSmall),function(v,i){return _c('el-col',{key:i,attrs:{"xs":8,"sm":6}},[_c('div',{staticClass:"img-box",on:{"click":function($event){return _vm.$router.push({
              path: '/profile',
              query: {
                user_id: v.user_id,
              },
            })}}},[_c('el-image',{staticStyle:{"width":"70px","height":"70px","cursor":"pointer"},attrs:{"src":v.avatar,"fit":"cover"}}),(v.role == 3)?_c('a',{class:{ personalClass: v.role == 3 ? true : false },attrs:{"target":"__blank","href":v.link}},[_vm._v(_vm._s(v.name))]):_c('span',[_vm._v(_vm._s(v.name))])],1)])}),1)],1):_vm._e(),(_vm.partner&&_vm.partner.length)?_c('section',{staticClass:"partner"},[_c('h3',[_vm._v("Partner")]),_c('el-row',{attrs:{"gutter":10}},_vm._l((_vm.partner),function(v,i){return _c('el-col',{key:i,attrs:{"xs":12,"sm":8}},[_c('div',{staticClass:"img-box",on:{"click":function($event){return _vm.$router.push({
              path: '/profile',
              query: {
                user_id: v.user_id,
              },
            })}}},[_c('el-image',{staticStyle:{"width":"90px","height":"90px","cursor":"pointer"},attrs:{"src":v.avatar,"fit":"cover"}}),(v.role == 3)?_c('a',{class:{ personalClass: v.role == 3 ? true : false },attrs:{"target":"__blank","href":v.link}},[_vm._v(_vm._s(v.name))]):_c('span',[_vm._v(_vm._s(v.name))])],1)])}),1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }