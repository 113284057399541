<template>
  <div class="introduce">
    <div class="intro" v-html="introduction&&introduction.introduction">
    </div>
    <!-- sponsor -->
    <section v-if="sponsorLarge&&sponsorLarge.length || sponsorSmall&&sponsorSmall.length" class="sponsors">
      <h3>Sponsor</h3>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="8" v-for="(v, i) in sponsorLarge" :key="i">
          <div
            @click="
              $router.push({
                path: '/profile',
                query: {
                  user_id: v.user_id,
                },
              })
            "
            class="img-box"
          >
            <el-image
              style="width: 90px; height: 90px; cursor: pointer"
              :src="v.avatar"
              fit="cover"
            ></el-image>
            <a
              v-if="v.role == 3"
              target="__blank"
              :class="{ personalClass: v.role == 3 ? true : false }"
              :href="v.link"
              >{{ v.name }}</a
            >
            <span v-else>{{ v.name }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="8" :sm="6" v-for="(v, i) in sponsorSmall" :key="i">
          <div
            @click="
              $router.push({
                path: '/profile',
                query: {
                  user_id: v.user_id,
                },
              })
            "
            class="img-box"
          >
            <el-image
              style="width: 70px; height: 70px; cursor: pointer"
              :src="v.avatar"
              fit="cover"
            ></el-image>
            <a
              v-if="v.role == 3"
              target="__blank"
              :class="{ personalClass: v.role == 3 ? true : false }"
              :href="v.link"
              >{{ v.name }}</a
            >
            <span v-else>{{ v.name }}</span>
          </div>
        </el-col>
      </el-row>
    </section>

    <!-- partner -->
    <section v-if="partner&&partner.length" class="partner">
      <h3>Partner</h3>
      <el-row :gutter="10">
        <el-col :xs="12" :sm="8" v-for="(v, i) in partner" :key="i">
          <div
            @click="
              $router.push({
                path: '/profile',
                query: {
                  user_id: v.user_id,
                },
              })
            "
            class="img-box"
          >
            <el-image
              style="width: 90px; height: 90px; cursor: pointer"
              :src="v.avatar"
              fit="cover"
            ></el-image>
            <a
              v-if="v.role == 3"
              target="__blank"
              :class="{ personalClass: v.role == 3 ? true : false }"
              :href="v.link"
              >{{ v.name }}</a
            >
            <span v-else>{{ v.name }}</span>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {

    };
  },
  props: ['introduction'],

  computed: {
    sponsorLarge () {
      return this.introduction.sponsor && this.introduction.sponsor.filter(v => v.crade == 1 || v.crade == 0)
    },
    sponsorSmall () {
      return this.introduction.sponsor && this.introduction.sponsor.filter(v => v.crade == 2)
    },
    partner () {
      return this.introduction.partner
    },
  },

  components: {},

  created () { },

  methods: {

  }
}

</script>
<style lang="scss" scoped>
.introduce {
  min-height: 500px;
  padding: 10px;
  .intro {
    // padding: 0 10px 20px;
  }
  .partner,
  .sponsors {
    margin-top: 50px;
    > h3 {
      font-size: 16px;
      color: #333;
      margin-bottom: 10px;
    }
  }
  .img-box {
    border: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;

    > span {
      color: #333;
      padding-top: 10px;
      // text-decoration: underline;
    }
    > a.personalClass {
      padding-top: 10px;
      text-decoration: underline;
      color: #50b5ff;
      cursor: pointer;
    }
  }
}
</style>